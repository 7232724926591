<script setup lang="ts"></script>

<template>
    <svg
        width="46"
        height="58"
        viewBox="0 0 46 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_di_631_53585)">
            <path
                d="M10 12.4208C10 6.11325 15.8203 1 23 1C30.1797 1 36 6.11325 36 12.4208C36 18.7283 27.0018 38.9999 23 39C18.9981 39.0001 10 18.7283 10 12.4208Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_di_631_53585"
                x="0"
                y="-3"
                width="46"
                height="61"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="9" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_631_53585"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_631_53585"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="-4" />
                <feGaussianBlur stdDeviation="3.5" />
                <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.725 0 0 0 0 0.725 0 0 0 0 0.725 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_631_53585"
                />
            </filter>
        </defs>
    </svg>
</template>
